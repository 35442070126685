exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-nursery-furniture-index-js": () => import("./../../../src/pages/nursery-furniture/index.js" /* webpackChunkName: "component---src-pages-nursery-furniture-index-js" */),
  "component---src-pages-safety-and-health-index-js": () => import("./../../../src/pages/safety-and-health/index.js" /* webpackChunkName: "component---src-pages-safety-and-health-index-js" */),
  "component---src-pages-strollers-and-car-seats-index-js": () => import("./../../../src/pages/strollers-and-car-seats/index.js" /* webpackChunkName: "component---src-pages-strollers-and-car-seats-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-toys-and-playtime-index-js": () => import("./../../../src/pages/toys-and-playtime/index.js" /* webpackChunkName: "component---src-pages-toys-and-playtime-index-js" */),
  "component---src-pages-travel-with-kids-index-js": () => import("./../../../src/pages/travel-with-kids/index.js" /* webpackChunkName: "component---src-pages-travel-with-kids-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

